import React from "react";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Hero from "common/hero";
import Seo from "common/seo";
import CategoryDetails from "common/category-details";
import AccordionContent from "common/accordion";
import { TypeA, TypeC } from "common/callouts";

import heroImageRef from "images/body/damaged-skin/body-damaged-skin-hero.webp";
import productInfoIcon from "images/icons/icon-damaged-skin-condition.png";
import productInfoImg from "images/body/damaged-skin/damaged-skin-data-visual.webp";
import img1 from "images/callouts/cout-dry-cracked-skin-condition.webp";
import img2 from "images/callouts/cout-wound-care-condition.webp";
import img3 from "images/callouts/cout-footer-eczema.webp";
import img4 from "images/callouts/cout-footer-itch-relief.webp";
import img5 from "images/callouts/cout-footer-dry-skin-therapy.webp";
import chartImg1 from "images/body/damaged-skin/chart-aqupahor-lanolin-process.webp";
import chartImg2 from "images/body/damaged-skin/chart-aqupahor-adverse-events.webp";

import "./damagedskin.scss";

const MyHero = () => <Hero className="inner-hero" bgImagePath={heroImageRef} />;

function DamagedSkinPage({ pageContext }) {
  return (
    <Layout
      pageClass="page-body-damaged-skin"
      breadcrumbData={pageContext.breadcrumb}
      hero={<MyHero />}
    >
      <Seo
        pageTitle="Damaged Skin"
        pageDescription="Learn about Aquaphor therapeutic solutions for damaged skin"
      />
      <div className="inner-body-content">
        <section className="inner-centered-container">
          <CategoryDetails
            headBgColor="#003E7E"
            headText="DAMAGED SKIN"
            infoText="For dry, cracked skin and minor wound care,<br class='show-desktop' /> healing starts here"
            bodySpanText="The therapeutic goal of damaged skin treatment&nbsp;"
            bodyText="is to protect the skin to help it heal. "
            productInfoIcon={productInfoIcon}
            productInfoIconAlt="Icon for therapeutic goal of damaged skin treatment"
            productInfoImg={productInfoImg}
            productInfoImgAlt="The Aquaphor Healing Ointment image"
          >
            <h5 className="dark-blue">
              The Aquaphor<sup>&reg;</sup> Approach goes beyond petrolatum
            </h5>
            <p>
              The unique, humectant-rich, <nobr>semi-occlusive</nobr> formulation protects
              the skin to enhance wound healing. With a clinically proven
              combination of ingredients, it creates an optimal healing
              environment for enhanced barrier repair.
            </p>
          </CategoryDetails>
        </section>
        <section className="callouts-container gradient-bg gray-bg">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12}>
                <p className="h1">Our purposeful formulation with:</p>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={4}>
                <p className="blue-text font-semi-bold font-18">
                  <span className="font-semi-bold blue-text font-25">
                    41% petrolatum{" "}
                    <span className="show-desktop inline blue-text">|</span>
                  </span>{" "}
                  <br className="show-mobile" />
                  Skin protectant
                </p>
                <ul className="blue-bullets">
                  <li>
                    <span>Protects exposed skin surfaces</span>
                  </li>
                  <li>
                    <span>Locks in moisture</span>
                  </li>
                </ul>
              </Col>
              <Col sm={12} md={4}>
                <p className="blue-text font-semi-bold font-18">
                  <span className="font-semi-bold blue-text font-25">
                    Panthenol <span className="show-desktop inline blue-text">|</span>
                  </span>{" "}
                  <br className="show-mobile" />
                  Humectant and skin conditioner
                </p>
                <ul className="blue-bullets">
                  <li>
                    <span>Softens and conditions skin</span>
                  </li>
                  <li>
                    <span>Binds water to support moisture</span>
                  </li>
                </ul>
              </Col>
              <Col sm={12} md={4}>
                <p className="blue-text font-semi-bold font-18">
                  <span className="font-semi-bold blue-text font-25">
                    Lanolin alcohol{" "}
                    <span className="show-desktop inline blue-text">|</span>
                  </span>{" "}
                  <br className="show-mobile" />
                  Barrier lipids{" "}
                </p>
                <ul className="blue-bullets">
                  <li>
                    <span>Supports barrier repair</span>
                  </li>
                  <li>
                    <span>Defends against moisture loss</span>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={4}>
                <p className="blue-text font-semi-bold font-18">
                  <span className="font-semi-bold blue-text font-25">
                    Glycerin <span className="show-desktop inline blue-text">|</span>
                  </span>{" "}
                  <br className="show-mobile" />
                  Humectant
                </p>
                <ul className="blue-bullets">
                  <li>
                    <span>Binds water</span>
                  </li>
                  <li>
                    <span>Replenishes moisture reservoir</span>
                  </li>
                </ul>
              </Col>
              <Col sm={12} md={4}>
                <p className="blue-text font-semi-bold font-18">
                  <span className="font-semi-bold blue-text font-25">
                    Bisabolol <span className="show-desktop inline blue-text">|</span>
                  </span>{" "}
                  <br className="show-mobile" />
                  Skin conditioner
                </p>
                <ul className="blue-bullets">
                  <li>
                    <span>Soothes dry, irritated skin</span>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <p className="font-semi-bold blue-text font-25">
                  Formulated for damaged skin
                </p>
                <ul className="checkmarks blue">
                  <li>
                    <span>hypoallergenic</span>
                  </li>
                  <li>
                    <span>non-comedogenic</span>
                  </li>
                  <li>
                    <span>preservative-free</span>
                  </li>
                  <li>
                    <span>Fragrance-free</span>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </section>
        <section className="callouts-container gradient-bg gray-grad border-top">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={8} lg={4} order={{ xs: 2, lg: 1 }}>
                <TypeC
                  head1={`Aquaphor Healing Ointment<sup>&reg;</sup> for dry, cracked skin`}
                  head1Css="dark-blue"
                  buttonText="See product information"
                  buttonUrl="/body/damagedskin/aquaphordrycrackedskin"
                  className="center-v"
                >
                  Helps heal dry, cracked skin with a humectant-rich formulation
                </TypeC>
              </Col>
              <Col sm={12} md={4} lg={2} order={{ xs: 1, lg: 2 }}>
                <img
                  src={img1}
                  alt="Cracked skin icon"
                  className="cout-product-img img-small"
                />
              </Col>
              <Col sm={12} md={8} lg={4} order={{ xs: 4, lg: 3 }}>
                <TypeC
                  head1={`Aquaphor Healing Ointment<sup>&reg;</sup> for minor wound care`}
                  head1Css="dark-blue"
                  buttonText="See product information"
                  buttonUrl="/body/damagedskin/aquaphorwoundcare"
                  className="center-v"
                >
                  Helps heal minor post-procedure wounds with a breathable
                  barrier
                </TypeC>
              </Col>
              <Col sm={12} md={4} lg={2} order={{ xs: 3, lg: 4 }}>
                <img
                  src={img2}
                  alt="Wound care icon"
                  className="cout-product-img img-small"
                />
              </Col>
            </Row>
          </div>
        </section>
        <div className="gradient-bg">
          <section className="inner-centered-container">
            <AccordionContent
              accordionHeading="Lanolin purification"
              productThemeColor="eucerin-blue first"
            >
              <Row direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={chartImg1}
                    className="accordion-chart"
                    alt="Showing Lanolin purification process"
                  />
                </Col>
              </Row>
            </AccordionContent>
            <AccordionContent
              accordionHeading="Tolerability"
              productThemeColor="eucerin-blue"
            >
              <Row direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={chartImg2}
                    className="accordion-chart"
                    alt="Chart showing tolerability study data"
                  />
                  <p className="footnotes">
                    <span className="font-bold">Study design:</span> Open-label
                    study of 499 subjects (ages <nobr>18-75</nobr>) in three centers
                    undergoing a variety of <nobr>in-office</nobr> surgical procedures who
                    applied Aquaphor Healing Ointment at least once daily and up
                    to 3 times daily, followed by evaluation of wound sites for
                    erythema, itching, pain, signs of infection, and allergic
                    contact dermatitis after 10-14 days.
                  </p>
                  <p className="references">
                    <span className="font-bold">References: 1.</span> Draelos ZD,
                    Kircik LH, Rigel D. The low prevalence of allergic contact
                    dermatitis using a petrolatum ointment containing lanolin
                    alcohol. <em>J Drugs Dermatol</em>. 2019;18(10):1002-1004.{" "}
                    <span className="font-bold">2.</span> Data on file.
                    Beiersdorf Inc.
                  </p>
                </Col>
              </Row>
            </AccordionContent>
          </section>
        </div>
        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img3}
                  imgAlt="Eczema Relief products images"
                  caption="Choose a proactive therapeutic<br class='show-desktop' /> regimen for eczema"
                  buttonText="Eczema Regimen"
                  buttonUrl="/body/eczema"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img4}
                  imgAlt="Itch Relief products images"
                  caption="Offer immediate and <nobr>long-lasting</nobr> itch relief"
                  buttonText="Itch Relief"
                  buttonUrl="/body/itch"
                  imgStyles={{ height: 165 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img5}
                  imgAlt="Eczema Advanced Repair products images"
                  caption="Replenish what nature provided<br class='show-desktop' /> for dry skin"
                  buttonText="Dry Skin Therapy"
                  buttonUrl="/body/dryskin"
                  imgStyles={{ height: 165 }}
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default DamagedSkinPage;
